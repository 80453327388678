<template>
  <component :is="empresaData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="empresaData === undefined"
    >
      <h4 class="alert-heading">
       Cliente no encontrado.
      </h4>
      <div class="alert-body">
        No se encontró ningún Cliente con este ID.
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          Listado empresa
        </b-link>
      </div>

    </b-alert>

    <b-tabs
      v-if="empresaData"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Cuenta</span>
        </template>
        <empresa-edit-tab-account :empresa-data="empresaData" />
      </b-tab>
      
      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">CSD</span>
        </template>
        <b-row>
          <b-col
          cols="12"
          md="5"
          xl="3"
          lg="5" v-if="actualizando"
        >
        <empresa-upload-cer/>
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="5"
          v-if="!actualizando"
        >
        <b-card
          no-body
          class="border-success"  v-if="getCert"
        >
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <h5 class="mb-0">
              Certificado Actual
            </h5>
            <b-badge variant="light-success">
              Activo
            </b-badge>
            <small class="text-muted w-100">{{getCert.rfc}}</small>
          </b-card-header>
          <b-card-body>
            <ul class="list-unstyled my-1">
              <li>Certificado:
                <span class="align-middle"><b-badge variant="light-success">
              {{getCert.numeroCertificado}}
            </b-badge></span>
              </li>
              <li class="my-25">Inicio Vigencia:
                <span class="align-middle"><b-badge variant="light-success">
             {{getCert.fechaInicioVigencia}}
            </b-badge></span>
              </li>
              <li>Fin Vigencia:
                <span class="align-middle"><b-badge variant="light-success">
             {{getCert.fechaFinVigencia}}
            </b-badge></span>
              </li>
            </ul>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              v-on:click="update"
            >
              Cambiar Certificado
            </b-button>
          </b-card-body>
        </b-card>
        </b-col>
        <b-col cols="12" lg="8" v-if="certData">
         <empresa-detail-cert :cert-data="certData" />
        </b-col>
      </b-row>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,BTab, BTabs, BAlert, BLink,BRow,BCol,BBadge, BButton,BFormGroup,BFormInput
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import empresaStore from '../empresaStore'
import EmpresaEditTabAccount from './EmpresaEditTabAccount'
import EmpresaConfigSat from './EmpresaConfigSat'
import EmpresaCsd from './EmpresaCsd.vue'
import EmpresaUploadKey from './EmpresaUploadKey.vue'
import EmpresaUploadCer from './EmpresaUploadCer.vue'
import EmpresaDetailCert from './EmpresaDetailCert.vue'
import useEmpresaList from '../useEmpresaList'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BTab,
    BTabs,
    BAlert,
    BLink,
    EmpresaEditTabAccount,
    EmpresaConfigSat,
    EmpresaCsd,
    EmpresaUploadKey,
    EmpresaUploadCer,
    EmpresaDetailCert,
    BRow,BCol,
    BBadge, BButton,
    BFormGroup,
    BFormInput
  },
  setup() {
    const empresaData= ref(null)
    const certData=ref(null)
    const uploadNew=ref(false)
    
    const {subirNuevo}=useEmpresaList()
    const USER_APP_STORE_MODULE_NAME = 'app-empresas'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, empresaStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const currentCert=store.state['app-empresas'].certificate;
    
    //let evalid=router.currentRoute.params.id;
  //if(router.currentRoute.params.id>1){

  store.dispatch('app-empresas/fetchEmpresa', { id: router.currentRoute.params.id })
        .then(response => {
        empresaData.value = response.data ;
        store.commit('app-empresas/SET_CURRENT_EMPRESA',response.data)})
        .catch(error => {
          if (error.response.status === 404) {
            empresaData.value = undefined
          }
        })
        store.dispatch('app-empresas/fetchCertificados',{id:router.currentRoute.params.id})
        .then(resp=>{
          certData.value=resp.data;
          for(let item of certData.value){
          // console.log(item)575134
            if(item.estatus_id==1){
              store.commit('app-empresas/SET_CURRENT_CERT',item)
            }
          }
          if(!store.state['app-empresas'].certificate){
            store.commit('app-empresas/SET_UPDATE',true)
          }

        }).catch(err=>{

        })
  //}
    

      const update=()=>{
        store.commit('app-empresas/SET_UPDATE',true)
      }
    
    return {
      empresaData,
      certData,
      uploadNew,
      subirNuevo,
      currentCert,
      update
    }
  },
  directives:{
    Ripple
  },
  computed:{
    getCert(){
      return store.state['app-empresas'].certificate
    },
    actualizando(){
      return store.state['app-empresas'].subirnuevo
    }
  },
  methods:{
    
  }
}
</script>

<style>

</style>
