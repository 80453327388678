<template>
  <div>
    <b-overlay
      :show="isSubmited"
      rounded="sm"
    >
    <validation-observer
        #default="{ handleSubmit }"
       ref="refFormObserver"
      >
    <!-- User Info: Input Fields -->
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
        <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Razon Social"
            rules="required"
          >
            <b-form-group
              label="Razon Social"
              label-for="razon-social"
            >
              <b-form-input
                id="razon-social"
                v-model="empresaData.razonSocial"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="6"
          md="2"
        >
        <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="RFC"
            rules="required"
          > 
          <b-form-group
            label="RFC"
            label-for="rfc"
          >
            <b-form-input
              id="rfc"
              v-model="empresaData.rfc"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          </validation-provider>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="6"
          md="3"
        >
         <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="empresaData.email"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="3"
          lg="3"
        >
          <b-form-group
            label="Estatus"
            label-for="estatus"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="estatus"
              v-model="empresaData.estatus_id"
              :options="estatusOption"
              value="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Direccion
        </h4>
      </div>
      <b-row>
        <!-- Field: Address Line 1 -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Calle"
            label-for="calle"
          >
            <b-form-input
              id="calle"
              v-model="empresaData.calle"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 2 -->
        <b-col
          cols="6"
          md="2"
          lg="2"
        >
          <b-form-group
            label="Numero Interior"
            label-for="numeroInterior"
          >
            <b-form-input
              id="numeroInterior"
              v-model="empresaData.numeroInterior"
              placeholder="Los Santos"
            />
          </b-form-group>
        </b-col>
   <!-- Field: Address Line 2 -->
        <b-col
          cols="6"
          md="2"
          lg="2"
        >
          <b-form-group
            label="Numero Exterior"
            label-for="numeroExterior"
          >
            <b-form-input
              id="numeroExterior"
              v-model="empresaData.numeroExterior"
              placeholder="Los Santos"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Postcode -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Colonia"
            label-for="colonia"
          >
            <b-form-input
              id="colonia"
              v-model="empresaData.colonia"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="6"
          md="2"
          lg="2"
        >
          <b-form-group
            label="Codigo Postal"
            label-for="codigoPostal"
          >
            <b-form-input
              id="codigoPostal"
              v-model="empresaData.codigoPostal"
            />
          </b-form-group>
        </b-col>
        <!-- Field: City -->
        <b-col
          cols="6"
          md="2"
          lg="2"
        >
          <b-form-group
            label="Municipio"
            label-for="municipio"
          >
            <b-form-input
              id="municipio"
              v-model="empresaData.municipio"
            />
          </b-form-group>
        </b-col>
        <!-- Field: City -->
        <b-col
          cols="6"
          md="2"
          lg="2"
        >
          <b-form-group
            label="Estado"
            label-for="estado"
          >
            <b-form-input
              id="estado"
              v-model="empresaData.estado"
            />
          </b-form-group>
        </b-col>
        <!-- Field: City -->
        <b-col
          cols="6"
          md="2"
          lg="2"
        >
          <b-form-group
            label="Pais"
            label-for="pais"
          >
            <b-form-input
              id="pais"
              v-model="empresaData.pais"
            />
          </b-form-group>
        </b-col>
      </b-row>
<!-- Form Actions -->
          
          <div class="d-flex mt-2">
                <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Guardar Cambios
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :to="{ name: 'empresas-list'}">
                Cancelar
              </b-button>

          </div>
    </b-form>
    </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput,
  BFormRadioGroup, 
  BForm,
  BTable, 
  BCard, 
  BCardHeader, 
  BCardTitle, 
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormRadioGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay
  },
  props: {
    empresaData:{
      type:Object,
      required:true
    }
  },
  data(){
 return {
      required,
      alphaNum,
      email
    }
  },
  setup(props) {

// Use toast
  const toast = useToast()
  const estatusOption = [
      { text: 'Activo', value: '1' },
      { text: 'Inactivo', value: '2' },
    ]
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Mantenimiento', value: 'maintentiente' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
  /*  const clientData=ref(JSON.parse(JSON.stringify(blankClientData)));

    const resetClientData=()=>{
      clientData.value=JSON.parse(JSON.stringify(blankClientData));
    }*/

const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const isSubmited=ref(false)

    const onSubmit = () => {
      isSubmited.value=true;
      let endpoint= `app-empresas/addEmpresa`;
      if(props.empresaData.id>0){
        endpoint=`app-empresas/updateEmpresa`;
      }
          store.dispatch(endpoint, props.empresaData)
            .then((resdata) => {

              store.commit('app-empresas/SET_CURRENT_EMPRESA',resdata.data);
              
              toast({
                  component: ToastificationContent,
                  props: {
                    title: "Almacenado correctamente.",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                isSubmited.value=false;
            }).catch(error => {
            toast({
              component: ToastificationContent,
                  props: {
                    title: "Error al guardar empresa.",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
        })

        }
      return {
      avatarText,
      roleOptions,
      statusOptions,
      estatusOption,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      onSubmit,
      refFormObserver,
      getValidationState,
      isSubmited
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
