<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Subir nuevo csd (.cer)
      </h5>
      <b-badge variant="light-success" v-if="todoOk">
        Valido
      </b-badge>
      <small class="text-muted w-100">{{currentEmpresa.rfc}}</small>
    </b-card-header>
    <b-card-body>
      <div v-if="!todoOk">
      Certificado (.cer):
      <b-form-file
          v-model="cerFile"
          accept=".cer"
          />

        Llave privada (.key):
        <b-form-file
        id="refInputKey"
        v-model="keyFile"
        accept=".key"/>
        <b-form-group
          label="Contraseña llave privada:"
          label-for="password"
        >
          <b-form-input
            id="password"
            value=""
            v-model="password"
          />
        </b-form-group>
      </div>
      <ul class="list-unstyled my-1" v-if="certificate">
        <li>
            <b-form-input
                id="account-new-password"
                v-model="certificate.nombre"
                name="new-nombre"
                placeholder="Nombre o identificador"
              />
        </li>
        <li>
          <b-form-group
          label="Serie"
          label-for="serialNumber"
        >
          <b-form-input
            id="serialNumber"
            value="000000000"
            v-model="certificate.serialNumber"
            readonly
          />
        </b-form-group>
        </li>
        <li class="my-25">
          <b-form-group
          label="Certificado"
          label-for="certificado"
        >
          <b-form-input
            id="certificado"
            value="000000000"
            v-model="certificate.numeroCertificado"
            readonly
          />
        </b-form-group>
        </li>
        <li>
          <b-form-group
          label="Inicio Vigencia"
          label-for="fechaInicioVigencia"
        >
          <b-form-input
            id="fechaInicioVigencia"
            value="000000000"
            v-model="certificate.fechaInicioVigencia"
            readonly
          />
        </b-form-group>
        </li>
        <li>
          <b-form-group
          label="Fin Vigencia"
          label-for="fechaFinVigencia"
        >
          <b-form-input
            id="fechaFinVigencia"
            value="000000000"
            v-model="certificate.fechaFinVigencia"
            readonly
          />
        </b-form-group>
        </li>
      </ul>
      <div v-if="!certificate">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            v-if="!cargandoCer"
            v-on:click="uploadCer"
        >
            Validar
        </b-button>
      <b-spinner small v-if="cargandoCer"/>
      <span class="sr-only" v-if="cargandoCer">Loading...</span>
      <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="sm"
            class="mb-75 mr-75"
            v-if="!cargandoCer"
            v-on:click="clearAll"
        >
            Descartar Cambios
        </b-button>
      </div>

      <div v-if="certificate">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            v-on:click="saveCsd"
        >
            Guardar
        </b-button>
              <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="sm"
            class="mb-75 mr-75"
            v-on:click="resetForm"
        >
            Cancelar
        </b-button>
      </div>
      
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
  BForm,BFormFile,
  BFormInput,
  BFormGroup,
  BSpinner,
  BPopover,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { API_URL } from '@/env/environment'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useEmpresaList from '../useEmpresaList'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BForm,
    BFormFile,
    BFormInput,
    BSpinner,
    BFormGroup,
    BPopover,
    BRow,
  BCol,
  },
  setup(){
const toast=useToast();
const { subirNuevo }=useEmpresaList()
    return {
        toast,
        subirNuevo
    }
  },
data() {
    
    return {
      password:'',
      nombre:'',
      cargandoCer:false,
      cargandoKey:false,
      passwordFieldTypeNew: 'password',
      cerFile: null,
      keyFile: null,
      certificate:null,
      todoOk:false,
      localOptions:{} //JSON.parse(JSON.stringify(this.socialData)),
    }
  },
    methods: {
      clearAll(){
        this.resetForm()
      store.commit('app-empresas/SET_UPDATE',false)
      },
    resetForm() {
        this.cerFile=null;
        this.keyFile=null;
        this.nombre='';
        this.certificate=null;
        this.todoOk=false;
      //this.localOptions = JSON.parse(JSON.stringify(this.socialData))
    },
      togglePasswordNew() {
          
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    uploadCer(){
     // console.log('this is bussness',this.currentEmpresa)
        this.cargandoCer=true;
      let formData = new FormData();
      formData.append('archivo', this.cerFile);
      formData.append('llave', this.keyFile);
      formData.append('password',this.password);
      formData.append('rfcEmpresa',this.currentEmpresa.rfc)
       axios.post(`${API_URL}empresas/uploadcer`,formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{
            this.todoOk=true;
            this.certificate=res.data;
            this.cargandoCer=false;
            this.$swal({
              title: 'Certificado y Llave privada Valido!',
              text: 'Complete los datos requeridos y Guarde los cambios.!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            store.commit('app-empresas/SET_CURRENT_CERT',res.data)
            //this.clearAll()
            //store.commit('app-empresas/SET_UPDATE',false)
        }).catch(err=>{

        this.$swal({
        title: 'Error!',
        text: err.data.message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-warning',
        },
        buttonsStyling: false,
      })
                this.cargandoCer=false;
        })
    },
    saveCsd(){
        this.certificate.empresas_id=this.currentEmpresa.id;
        this.certificate.estatus_id=1;
       axios.post(`${API_URL}empresas/savecsd`,this.certificate).then(res=>{
             // this.certificate=res.data;
            this.$swal({
              title: 'CSD y Llave privada registrado Correctamente!',
              text: 'Ya puede realizar timbrado.!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            //store.commit('app-empresas/SET_CURRENT_CERT',res.data)
            this.clearAll();
        }).catch(err=>{
             console.log(err.data);
            toast({
              component: ToastificationContent,
                  props: {
                    title: "Error al actualizar cliente.",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
        })
    }
  },
  computed:{
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    currentEmpresa(){
      return store.state['app-empresas'].currentEmpresa;
    }
  }
}
</script>

<style>

</style>
