import axios from '@axios'
import { API_URL } from '@/env/environment'

export default {
  namespaced: true,
  state: {
    subirnuevo:false,
    certificate:null,
    currentEmpresa:null
  },
  getters: {},
  mutations: {
    SET_CURRENT_CERT(state,val){
      state.certificate=val;
    },
    SET_UPDATE(state,val){
      state.subirnuevo=val;
    },
    SET_CURRENT_EMPRESA(state,val){
      state.currentEmpresa=val;
    },
  },
  actions: {
    fetchEmpresas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}empresas`, { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addEmpresa(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}empresas`, clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmpresa(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}empresas/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGetCSD(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}empresas/csd/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCertificados(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}empresas/getcsds/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveCsd(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}empresas/savecsd`,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmpresa(ctx,clientData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${API_URL}empresas/${clientData.id}`,clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCredential(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}integrador/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    } , 
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/user/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
