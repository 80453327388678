<template>
  <b-card>
    <b-form>
      <b-row>
        <!-- Profile Connections -->
        <b-col
          cols="12"
          class="mt-1"
        >
          <b-row class="text-center">
            <!-- twitter profile -->
            <b-col
              md="3"
              cols="6"
              class="mb-1"
            >
              <b-card-text class="font-weight-bold">
                Certificado (.cer)
              </b-card-text>
              <div class="mb-1">
               <!-- upload button Y6s E4:42:13:A9-->
                <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
                >
                Subir Nuevo Certificado
                </b-button>
                <b-form-file
                ref="refInputEl"
                v-model="cerFile"
                :hidden="true"
                accept=".cer"
                plain
                @input="uploadCer"
                />
              </div>
              <b-card-text class="mb-0" v-if="certificate">
                RFC:{{certificate.rfc}}<br>
                Razonsocial:{{certificate.razonSocial}}<br>
                Serie:{{certificate.serialNumber}}<br>
                Certificado:{{certificate.numeroCertificado}}<br>
                Inicio Vigencia:{{certificate.fechaInicioVigencia}}<br>
                Fin Vigencia:{{certificate.fechaFinVigencia}}<br>
              </b-card-text>
            </b-col>
            <!--/ twitter profile -->

            <!-- facebook connect button -->
            <b-col
              md="3"
              cols="6"
              class="mb-1"
            >
              <b-card-text class="font-weight-bold mb-2">
                Clave privada (.key)
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl2.$el.click()"
                >
                Upload
                </b-button>
                <b-form-file
                ref="refInputEl2"
                v-model="keyFile"
                :hidden="true"
                accept=".key"
                plain
                @input="uploadKey"
                />
            <b-form-group
                label-for="account-new-password"
                label="Contraseña de clave privada:">
            
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
            </b-col>
            <!--/ facebook connect button -->

            <!-- google profile -->
            <b-col
              md="3"
              cols="6"
              class="mb-1"
            >
              
            </b-col>
            <!--/ google profile -->

            <!-- github connect -->
            <b-col
              md="3"
              cols="6"
              class="mb-1"
            >
              
            </b-col>
            <!--/ github connect -->
          </b-row>
        </b-col>

        <!-- buttons -->
        <b-col cols="12">

        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,BFormFile,BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import axios from '@axios'
import { API_URL } from '@/env/environment'
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
    empresaData:{
      type:Object,
      required:true
    }
  },
  data() {
    return {
      password:'',
      passwordFieldTypeNew: 'password',
      cerFile: null,
      keyFile: null,
      certificate:null,
      localOptions:{} //JSON.parse(JSON.stringify(this.socialData)),
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData))
    },
      togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    uploadCer(){
      let formData = new FormData();
      formData.append('archivo', this.cerFile);
       axios.post(`${API_URL}empresas/uploadcer`,formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{
              this.certificate=res.data;
            console.log(res);
        })
    },
    uploadKey(){
      let formData = new FormData();
      formData.append('archivo', this.keyFile)
      formData.append('pass',this.password)
       axios.post(`${API_URL}empresas/uploadkey`,formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{
             // this.certificate=res.data;
            console.log(res);
        })
    }
  },
  computed:{
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  }
}
</script>