<template>
  <b-card
    no-body
  >
      <b-card-title>Historial Certificados</b-card-title>
 
    <b-table
      striped
      responsive
      :items="certData"
      class="mb-0"
      :fields="fieldsCert"
    >

      <template #cell(rfc)="data">
        {{ data.value }}
      </template>
      <template #cell(estatus_id)="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    certData:[]
  },
  setup() {
    const  fieldsCert= ['id', 'rfc', 'numeroCertificado','fechaFinVigencia'];
    return {
      fieldsCert,
    }
  },
}
</script>

<style>

</style>
